import { showToast, useAuth } from '@IJGN/potential'
import { Link } from '@remix-run/react'
import { useQueryClient } from '@tanstack/react-query'
import { type FormEvent, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useSharedData } from '~/context/SharedDataProvider'
import { DISPLAY_CANCELED_SURVEYS_KEY } from '~/lib/localStorage'
import usePopupVisibility from '../hooks/usePopupVisibility'
import BgMainGradation from '../images/logo/sidebar/bg-main.png'
import { del } from '../lib/fetch_api'

export default function Sidebar() {
  const { user } = useAuth()
  const { company, isAdmin } = useSharedData()
  const { survey: survey_code, company: company_code } = useParams<{
    survey: string
    company: string
  }>()
  const [companyName, setCompanyName] = useState<string>('')

  // MEMO: 現在のページについて
  const location = useLocation()

  // MEMO: 以下、システム管理
  // ダッシュボード
  const pageCompanySystemDashboard = location.pathname === '/admin/dashboard'
  // 企業管理
  const pageCompanyManagement =
    location.pathname === '/companies' ||
    location.pathname.startsWith('/companies/new') ||
    location.pathname.startsWith(`/companies/${company_code}/edit`)
  // 質問マスター管理
  const pageMasterQuestionsets = location.pathname.startsWith('/question_sets')
  // コンサルタント管理
  const pageConsultants = location.pathname.startsWith('/consultant')
  // マイページ
  const pageAdminMyPage = location.pathname.startsWith('/admin/mypage')

  // MEMO: 以下、企業コンサルティング
  // ダッシュボード
  const pageCompanyConsultingDashboard = location.pathname === `/companies/${company_code}/dashboard`
  // サーベイ管理
  const pageCompanySurveys =
    location.pathname.startsWith(`/companies/${company_code}/surveys`) &&
    !location.pathname.startsWith(`/companies/${company_code}/surveys/${survey_code}/report`)
  // 質問管理
  const pageCompanyQuestionsets = location.pathname.startsWith(`/companies/${company_code}/question_sets`)
  // 属性管理
  const pageCompanyAttr = location.pathname.startsWith(`/companies/${company_code}/attr_question`)
  // 企業担当者管理
  const pageCompanyPersons = location.pathname.startsWith(`/companies/${company_code}/persons`)
  // マイページ
  const pageCompanyMyPage = location.pathname.startsWith('/companies/mypage')

  const queryClient = useQueryClient()

  // ログアウト
  const handleLogout = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!confirm('ログアウトしてよろしいでしょうか')) return

    const loginPath = isAdmin ? '/admin/login' : '/login'

    del('/session', {})
      .then(() => {
        window.location.href = loginPath
      })
      .catch(() => {
        showToast.error('ログアウトに失敗しました')
      })
    queryClient.clear()

    localStorage.removeItem(DISPLAY_CANCELED_SURVEYS_KEY)
  }

  useEffect(() => {
    if (company) {
      setCompanyName(company.name)
    }
  }, [company])

  // サイドバー ポップアップ表示
  // 各ポップアップの表示状態を管理するステート
  const { visiblePopupId, handleMouseEnter, handleMouseLeave } = usePopupVisibility()

  return (
    <div
      className='l-management-screen__sidebar flex bg-no-repeat bg-left-bottom bg-cover'
      style={{ backgroundImage: `url(${BgMainGradation})` }}
    >
      {/* カテゴリ */}
      <div className='l-management-screen__sidebar-main relative z-10 h-full p-4 flex flex-col items-center justify-between'>
        <div className='relative z-30 flex flex-col gap-4'>
          {/* システム管理 */}
          {(pageCompanySystemDashboard ||
            pageCompanyManagement ||
            pageMasterQuestionsets ||
            pageConsultants ||
            pageAdminMyPage) && (
            <>
              {/* ダッシュボード */}
              <div
                className={`flex items-center justify-center w-11 h-11 rounded-lg ${
                  pageCompanySystemDashboard ? 'pointer-events-none' : ''
                }`}
              >
                <Link
                  to='/admin/dashboard'
                  className={`flex justify-center items-center w-11 h-11 icon-before icon-light text-xl icon-grid-horizontal cursor-pointer rounded-lg border border-solid border-knowledge-navy-3 text-white ${
                    pageCompanySystemDashboard ? 'bg-white/50 pointer-events-none' : 'bg-white/20 hover:bg-white/50'
                  }`}
                />
              </div>
              {/* 企業管理 */}
              <div
                className={`flex items-center justify-center w-11 h-11 rounded-lg ${
                  pageCompanyManagement ? 'pointer-events-none' : ''
                }`}
              >
                <Link
                  to='/companies'
                  className={`flex justify-center items-center w-11 h-11 icon-before icon-light text-xl icon-buildings cursor-pointer rounded-lg border border-solid border-knowledge-navy-3 text-white ${
                    pageCompanyManagement ? 'bg-white/50 pointer-events-none' : 'bg-white/20 hover:bg-white/50'
                  }`}
                />
              </div>
              {/* 質問マスター管理 */}
              <div
                className={`flex items-center justify-center w-11 h-11 rounded-lg ${
                  pageMasterQuestionsets ? 'pointer-events-none' : ''
                }`}
              >
                <Link
                  to='/question_sets'
                  className={`flex justify-center items-center w-11 h-11 icon-before icon-light text-xl icon-comments-question-check cursor-pointer rounded-lg border border-solid border-knowledge-navy-3 text-white ${
                    pageMasterQuestionsets ? 'bg-white/50 pointer-events-none' : 'bg-white/20 hover:bg-white/50'
                  }`}
                />
              </div>
              {/* コンサルタント管理 */}
              <div
                className={`flex items-center justify-center w-11 h-11 rounded-lg ${
                  pageConsultants ? 'pointer-events-none' : ''
                }`}
              >
                <Link
                  to='/consultant'
                  className={`flex justify-center items-center w-11 h-11 icon-before icon-light text-xl icon-user-tie cursor-pointer rounded-lg border border-solid border-knowledge-navy-3 text-white ${
                    pageConsultants ? 'bg-white/50 pointer-events-none' : 'bg-white/20 hover:bg-white/50'
                  }`}
                />
              </div>
            </>
          )}
          {/* 企業コンサルティング */}
          {(pageCompanyConsultingDashboard ||
            pageCompanySurveys ||
            pageCompanyQuestionsets ||
            pageCompanyAttr ||
            pageCompanyPersons ||
            pageCompanyMyPage) && (
            <>
              {/* ダッシュボード */}
              <div
                className={`flex items-center justify-center w-11 h-11 rounded-lg ${
                  pageCompanyConsultingDashboard ? 'pointer-events-none' : ''
                }`}
              >
                <Link
                  to={`/companies/${company ? company.code : company_code}/dashboard`}
                  className={`flex justify-center items-center w-11 h-11 icon-before icon-light text-xl icon-grid-horizontal cursor-pointer rounded-lg border border-solid border-knowledge-navy-3 text-white ${
                    pageCompanyConsultingDashboard ? 'bg-white/50 pointer-events-none' : 'bg-white/20 hover:bg-white/50'
                  }`}
                />
              </div>
              {/* サーベイ管理 */}
              <div
                className={`flex items-center justify-center w-11 h-11 rounded-lg ${
                  pageCompanySurveys ? 'pointer-events-none' : ''
                }`}
              >
                <Link
                  to={`/companies/${company ? company.code : company_code}/surveys`}
                  className={`flex justify-center items-center w-11 h-11 icon-before icon-light text-xl icon-clipboard-list-check cursor-pointer rounded-lg border border-solid border-knowledge-navy-3 text-white ${
                    pageCompanySurveys ? 'bg-white/50 pointer-events-none' : 'bg-white/20 hover:bg-white/50'
                  }`}
                />
              </div>
              {/* 質問管理 */}
              {isAdmin && (
                <div
                  className={`flex items-center justify-center w-11 h-11 rounded-lg ${
                    pageCompanyQuestionsets ? 'pointer-events-none' : ''
                  }`}
                >
                  <Link
                    to={`/companies/${company ? company.code : company_code}/question_sets`}
                    className={`flex justify-center items-center w-11 h-11 icon-before icon-light text-xl icon-comments-question-check cursor-pointer rounded-lg border border-solid border-knowledge-navy-3 text-white ${
                      pageCompanyQuestionsets ? 'bg-white/50 pointer-events-none' : 'bg-white/20 hover:bg-white/50'
                    }`}
                  />
                </div>
              )}
              {/* 属性管理 */}
              <div
                className={`flex items-center justify-center w-11 h-11 rounded-lg ${
                  pageCompanyAttr ? 'pointer-events-none' : ''
                }`}
              >
                <Link
                  to={`/companies/${company ? company.code : company_code}/attr_question`}
                  className={`flex justify-center items-center w-11 h-11 icon-before icon-light text-xl icon-tags cursor-pointer rounded-lg border border-solid border-knowledge-navy-3 text-white ${
                    pageCompanyAttr ? 'bg-white/50 pointer-events-none' : 'bg-white/20 hover:bg-white/50'
                  }`}
                />
              </div>
              {/* 企業担当者管理 */}
              <div
                className={`flex items-center justify-center w-11 h-11 rounded-lg ${
                  pageCompanyPersons ? 'pointer-events-none' : ''
                }`}
              >
                <Link
                  to={`/companies/${company ? company.code : company_code}/persons`}
                  className={`flex justify-center items-center w-11 h-11 icon-before icon-light text-xl icon-user-group cursor-pointer rounded-lg border border-solid border-knowledge-navy-3 text-white ${
                    pageCompanyPersons ? 'bg-white/50 pointer-events-none' : 'bg-white/20 hover:bg-white/50'
                  }`}
                />
              </div>
            </>
          )}
        </div>
        <ul className='relative z-30 flex flex-col items-center'>
          {/* ユーザー */}
          <li className='relative flex justify-center items-center w-11 h-11'>
            <span
              className='c-sidebar-content__button cursor-pointer flex justify-center items-center w-7 h-7 icon-before icon-regular text-[24px] text-white icon-circle-user'
              onMouseEnter={() => handleMouseEnter('sidebar-popup-user')}
              onMouseLeave={handleMouseLeave}
            />
            <div
              id='sidebar-popup-user'
              className={`absolute bottom-[48px] left-[12px] ${
                visiblePopupId === 'sidebar-popup-user' ? '' : 'hidden'
              }`}
              onMouseEnter={() => handleMouseEnter('sidebar-popup-user')}
              onMouseLeave={handleMouseLeave}
            >
              <div className='w-[300px] bg-white rounded-xl shadow flex-col justify-start items-start inline-flex overflow-hidden'>
                <div className='self-stretch px-5 py-3.5 bg-gray-5 justify-start items-start gap-2.5 inline-flex'>
                  <div className='grow shrink basis-0 flex-col justify-start items-start inline-flex'>
                    <div className='self-stretch text-gray-2 text-sm font-normal'>{!isAdmin && company?.name}</div>
                    <div className='self-stretch text-gray-1 text-sm font-bold'>{user?.name}</div>
                  </div>
                </div>
                <form
                  onSubmit={handleLogout}
                  className='self-stretch p-2 flex-col justify-start items-start flex'
                  autoComplete='off'
                >
                  {user && (
                    <Link
                      to={isAdmin ? '/admin/mypage' : '/companies/mypage'}
                      className='self-stretch px-3 py-2 rounded-lg justify-between items-center inline-flex hover:bg-gray-4 text-gray-1'
                    >
                      <span className='text-sm font-bold'>マイページ</span>
                      <span className='flex justify-center items-center w-5 h-5 icon-before icon-regular text-base text-black icon-chevron-right' />
                    </Link>
                  )}
                  <button
                    type='submit'
                    className='self-stretch px-3 py-2 rounded-lg justify-between items-center inline-flex hover:bg-gray-4 text-gray-1'
                  >
                    <span className='text-sm font-bold'>ログアウト</span>
                    <span className='flex justify-center items-center w-5 h-5 icon-before icon-regular text-base text-black icon-arrow-right-from-bracket' />
                  </button>
                </form>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}
